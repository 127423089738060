import { getMe } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";
import { FixLater } from "types";
import { checkRoleHasCapability } from "components/Restricted";

export type RestrictHasOneCapabilityProps = FixLater;

export const RestrictHasOneCapability = ({
  capabilities,
  alternate = null,
  children,
}: RestrictHasOneCapabilityProps) => {
  const me = useSelector(getMe);
  if (!me) {
    return null;
  }
  for (const capabilityID of capabilities) {
    if (checkRoleHasCapability(me, capabilityID)) {
      return children;
    }
  }
  return alternate;
};
