import { FormEvent, useEffect, useMemo, useState } from "react";
import { useModal } from "effects";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { runSampleProximitySensors, selectAssetByGUID, selectAssetsListByStorageId } from "store/reducers";
import { UUID } from "types";
import { TProximitySensorReport } from "types/features";
import { NumberInput } from "components/Input";
import Loading from "components/Loading";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import {
  StorageAssetSensorSettings,
  calculateProximitySettings,
  findAssetTakenFromProximitySensorReportValues,
  ProcessedProximitySensorValue,
  processProximitySensorReports,
  UsedSensor,
} from "components/shared/features/storages/utils/proximity.utility";
import { HSpace, VSpace } from "components/shared/layouts";
import { ModalPrimaryActionButton, ModalSecondaryActionButton } from "components/shared/modals";
import { TextBody } from "components/shared/typography";

const StyledModal = styled(Modal)`
  min-width: 30rem;
`;

type ScanToolSensorModalProps = {
  storageId: string;
  assetId: UUID;
  onSuccess: (settings: StorageAssetSensorSettings) => void;
};

export const ScanToolSensorModal = ({ storageId, assetId, onSuccess }: ScanToolSensorModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const assets = useSelector(selectAssetsListByStorageId(storageId));
  const asset = useSelector(selectAssetByGUID(assetId));
  const storageAsset = asset?.storageConfig;
  const calibrateOnly = Number(storageAsset?.irSensorBoardId) >= 1 && Number(storageAsset?.irSensorNumber) >= 0;
  const [fetching, setFetching] = useState<boolean>(false);
  const [sensorIssue, setSensorIssue] = useState<boolean>(false);
  const [cleanSensorReport, setCleanSensorReport] = useState<TProximitySensorReport[]>();
  const [dirtySensorReport, setDirtySensorReport] = useState<TProximitySensorReport[]>();
  const [cleanSensor, setCleanSensor] = useState<ProcessedProximitySensorValue>();
  const [dirtySensor, setDirtySensor] = useState<ProcessedProximitySensorValue>();
  const [settings, setSettings] = useState<StorageAssetSensorSettings>();
  const configuredAssets = useMemo(() => {
    return assets.filter(({ guid, storageConfig }) => {
      if (!storageConfig) return false;
      return storageConfig.irSensorBoardId && storageConfig.irSensorNumber;
    });
  }, [assets]);

  const fetchReports = async (): Promise<TProximitySensorReport[]> => {
    try {
      setFetching(true);
      const reports = await dispatch(
        runSampleProximitySensors({
          storageId,
        }),
      ).unwrap();

      // If we are calibrating a specific sensor, we filter the reports for that sensor.
      if (storageAsset && calibrateOnly) {
        return reports
          .filter((report) => report.boardId === storageAsset.irSensorBoardId)
          .map((report) => {
            return {
              ...report,
              values: report.values.filter((value) => value.position === storageAsset.irSensorNumber),
            };
          });
      }

      return reports;
    } catch (err) {
      handlePopupFlagError(err);
      return [];
    } finally {
      setFetching(false);
    }
  };

  const fetchDirtyReport = async () => {
    const result = await fetchReports();
    if (result.length) {
      setDirtySensorReport(result);
    }
  };

  const fetchCleanReport = async () => {
    const result = await fetchReports();
    if (result.length) {
      setCleanSensorReport(result);
    }
  };

  useEffect(() => {
    if (!cleanSensorReport || !dirtySensorReport) return;

    const ignoreList: UsedSensor[] = [];
    configuredAssets.forEach(({ guid, storageConfig }) => {
      if (!storageConfig) return false;

      const { irSensorBoardId, irSensorNumber } = storageConfig;
      // Check if this asset has an assigned sensor. If so, we will ignore that sensor when detecting
      // the sensor for the asset we are calibrating.
      if (typeof irSensorBoardId !== "number") return;
      if (typeof irSensorNumber !== "number") return;

      // If the asset is the same as the one we are calibrating, do not ignore the sensor. We will
      // calculate the threshold for this sensor.
      if (guid === assetId) return;
      ignoreList.push({ irSensorBoardId, irSensorNumber });
    });

    const processedClean = processProximitySensorReports(cleanSensorReport);
    const processedDirty = processProximitySensorReports(dirtySensorReport);
    const cleanFiltered = processedClean.filter((value) => {
      return !ignoreList.find(
        (ignoreValue) =>
          ignoreValue.irSensorBoardId === value.boardId && ignoreValue.irSensorNumber === value.sensorNumber,
      );
    });

    const { clean, dirty } = findAssetTakenFromProximitySensorReportValues(cleanFiltered, processedDirty);
    if (clean && dirty) {
      setCleanSensor(clean);
      setDirtySensor(dirty);
      setSettings(calculateProximitySettings(clean, dirty));
    } else {
      // Potentially detected a dead sensor
      if (calibrateOnly && !clean && !dirty) {
        setSensorIssue(true);
        return;
      }

      console.error("failed to find asset taken from clean or dirty reports", clean, dirty);

      closeModal();
      if (!clean && !dirty) {
        dispatch(
          showPopupFlag({
            appearance: "warning",
            title: "Failed to find any matching sensors with change",
          }),
        );
        return;
      }
      if (!clean) {
        dispatch(
          showPopupFlag({
            appearance: "warning",
            title: "Failed to match sensor picked up",
          }),
        );
        return;
      }
    }
  }, [cleanSensorReport, dirtySensorReport]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (!settings) return;
      closeModal();
      onSuccess(settings);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (fetching) {
    return (
      <StyledModal>
        <ModalHeader title="Scanning sensors" className="text-center">
          <TextBody textColor="text-gray-500">This will take 5 seconds...</TextBody>
        </ModalHeader>
        <div className="flex h-24 justify-center items-center">
          <Loading />
        </div>
      </StyledModal>
    );
  }

  if (sensorIssue) {
    return (
      <StyledModal>
        <ModalHeader title="⚠️ Sensor issue" className="text-center">
          <TextBody textColor="text-gray-500">
            Failed to read the sensor, please check the sensor and try again.{" "}
          </TextBody>
        </ModalHeader>
        <ModalContent>
          <ModalContent>
            <ModalPrimaryActionButton onClick={closeModal}>Okay</ModalPrimaryActionButton>
          </ModalContent>
        </ModalContent>
      </StyledModal>
    );
  }

  if (!cleanSensorReport) {
    return (
      <StyledModal>
        <ModalHeader title="Check the tool in the toolbox" className="text-center">
          <TextBody textColor="text-gray-500">Check that the tool is in the toolbox then click continue.</TextBody>
        </ModalHeader>
        <ModalContent>
          <ModalContent>
            <HSpace>
              <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
              <ModalPrimaryActionButton onClick={fetchCleanReport}>Continue</ModalPrimaryActionButton>
            </HSpace>
          </ModalContent>
        </ModalContent>
      </StyledModal>
    );
  }

  if (!dirtySensorReport) {
    return (
      <StyledModal>
        <ModalHeader title="Pick up the tool" className="text-center">
          <TextBody textColor="text-gray-500">Pick up the tool and then click continue.</TextBody>
        </ModalHeader>
        <ModalContent>
          <ModalContent>
            <HSpace>
              <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
              <ModalPrimaryActionButton onClick={fetchDirtyReport}>Continue</ModalPrimaryActionButton>
            </HSpace>
          </ModalContent>
        </ModalContent>
      </StyledModal>
    );
  }

  return (
    <StyledModal>
      <ModalHeader title="We found the sensor" className="text-center">
        <TextBody textColor="text-gray-500">
          Check the settings we found for the sensor and adjust if necessary.
        </TextBody>
      </ModalHeader>
      <ModalContent>
        {settings && (
          <VSpace>
            <FieldGroup label="Threshold">
              {cleanSensor && dirtySensor && (
                <TextBody textColor="text-gray-400">
                  Detected <span className="text-gray-600">{Math.floor(cleanSensor.value)}</span> when the tool was
                  stored and <span className="text-gray-600">{Math.floor(dirtySensor.value)}</span> when the tool was
                  taken.
                </TextBody>
              )}
              {settings && (
                <NumberInput
                  showKeyboard
                  input={{
                    name: "irSensorThreshold",
                    placeholder: "Enter threshold",
                  }}
                  value={settings.irSensorThreshold}
                  onChange={(name: string, value: number) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      irSensorThreshold: value,
                    }))
                  }
                />
              )}
            </FieldGroup>
            {!calibrateOnly && (
              <>
                <FieldGroup label="Sensor board">
                  <NumberInput
                    showKeyboard
                    input={{
                      name: "irSensorBoardId",
                      placeholder: "Enter sensor board number",
                    }}
                    value={settings?.irSensorBoardId}
                    onChange={(name: string, value: number) =>
                      setSettings((prevState) => ({
                        ...prevState,
                        irSensorBoardId: value,
                      }))
                    }
                  />
                </FieldGroup>
                <FieldGroup label="Sensor number">
                  <NumberInput
                    showKeyboard
                    input={{
                      name: "irSensorNumber",
                      placeholder: "Enter sensor number",
                    }}
                    value={settings?.irSensorNumber}
                    onChange={(name: string, value: number) =>
                      setSettings((prevState) => ({
                        ...prevState,
                        irSensorNumber: value,
                      }))
                    }
                  />
                </FieldGroup>
              </>
            )}
          </VSpace>
        )}
        <HSpace>
          <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
          <ModalPrimaryActionButton onClick={handleSubmit}>Apply settings</ModalPrimaryActionButton>
        </HSpace>
      </ModalContent>
    </StyledModal>
  );
};
