import Lookup from "lib/constants/Lookup";

class CheckoutTypes extends Lookup<number> {
  RFID = 1;
  MANUAL = 2;
  BARCODE = 3;
  TOOL_STORE = 4;
  PROXIMITY = 5;
  CASE_SCANNER = 6;
  MOBILE_APP = 7;

  constructor() {
    super();
    this.items = [
      {
        value: this.RFID,
        label: "RFID tracking",
        description: "System reported checkout",
      },
      {
        value: this.MANUAL,
        label: "User reported",
        description: "User reported checkout",
      },
      {
        value: this.BARCODE,
        label: "Barcode",
        description: "Barcode scanner checkout",
      },
      {
        value: this.TOOL_STORE,
        label: "Tool store",
        description: "Tool store checkout",
      },
      {
        value: this.PROXIMITY,
        label: "Automated tracking",
        description: "Proximity sensor checkout",
      },
      {
        value: this.CASE_SCANNER,
        label: "Case scanner",
        description: "Case scanner checkout",
      },
      {
        value: this.MOBILE_APP,
        label: "Mobile app",
        description: "Mobile app checkout",
      },
    ];
  }
}

export default new CheckoutTypes();
