import { useEffect, useState } from "react";
import { RiSignalTowerFill } from "react-icons/ri";
import cuid from "cuid";
import { useModal } from "effects/useModal";
import styled from "styled-components";
import colors from "tailwindcss/colors";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useSelector } from "store";
import { selectStorageByID } from "store/reducers";
import { FixLater, SVGShape, TStorageDrawer } from "types";
import { FileInput, NumberInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import { SecondaryButton, TrashButton } from "components/shared/buttons";
import { ScanDrawerSensorModal } from "components/shared/features/storages/modals/ScanDrawerSensorModal/ScanDrawerSensorModal";
import { DrawerSVG } from "components/shared/features/svgs";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { HSpace, VSpace } from "components/shared/layouts";
import { ModalPrimaryActionButton, ModalSecondaryActionButton } from "components/shared/modals";
import { Heading, TextBody } from "components/shared/typography";

const StyledModal = styled(Modal)`
  width: 100%;
  min-width: unset;

  @media only screen and (min-width: 768px) {
    max-width: 80vw;
  }
`;

const Content = styled(ModalContent)`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const SensorSettingsForm = styled(VSpace)`
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 20rem;
    flex: 1;
  }
`;

export const SVGContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background: ${colors.gray[50]};
  padding: 0.5rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-content: center;
`;

export const Uploader = styled(FileInput)`
  height: 15rem;
  max-width: 30rem;
  width: 100%;
  margin: 0 auto;
  align-self: center;
`;

type ConfigureDrawerLayoutModalProps = {
  drawer: TStorageDrawer;
  onSuccess: (value: TStorageDrawer) => void;
};

export const ConfigureDrawerLayoutModal = ({ drawer, onSuccess }: ConfigureDrawerLayoutModalProps) => {
  const { openModal, closeModal } = useModal();
  const storage = useSelector(selectStorageByID(drawer.storageId));
  const [viewbox, setViewbox] = useState<string>("");
  const [files, setFiles] = useState([]);
  const [fullSvg, setFullSvg] = useState<string>(drawer.fullSvg || "");
  const [shapes, setShapes] = useState<SVGShape[]>([]);
  const [drawerShape, setDrawerShape] = useState<SVGShape>();
  const [groupShape, setGroupShape] = useState<SVGShape>();
  const [values, setValues] = useState<TStorageDrawer>({
    ...drawer,
  });

  useEffect(() => {
    if (fullSvg) {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(fullSvg, "image/svg+xml");
      const svgElement = svgDoc.querySelector("svg");
      if (!svgElement) {
        handlePopupFlagError(null, "No SVG element found in the file");
        return;
      }

      // Pull out the SVG height, width, and viewport
      const viewBox = svgElement.getAttribute("viewBox");
      setViewbox(viewBox || "0 0 1000 1000");

      // Now extract all the path shapes.
      // @ts-ignore
      setGroupShape(svgDoc.querySelector("g") || null);

      // @ts-ignore
      const shapes = [...svgDoc.querySelectorAll("path")].map((shape): SVGShape => {
        const pathLength = shape.getTotalLength();
        return {
          id: cuid(),
          path: shape.getAttribute("d"),
          pathLength,
        };
      });
      setShapes(shapes);

      if (values.svgPath) {
        const defaultShape = shapes.find((shape) => shape.path === values.svgPath);
        if (defaultShape) {
          setDrawerShape(defaultShape);
        }
      } else {
        let largestShape;
        for (const shape of shapes) {
          if (!largestShape || shape.pathLength > largestShape.pathLength) {
            largestShape = shape;
          }
        }
        setDrawerShape(largestShape);
      }
    }
  }, [fullSvg]);

  const handleUpload = async (_: string, files: FixLater) => {
    try {
      setFiles(files);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const svgText = reader.result;
        setFullSvg(svgText as FixLater);
      };
      reader.readAsText(file);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleNumberChange = (name: string, value: FixLater) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      onSuccess({
        ...values,
        fullSvg,
        svgPath: drawerShape?.path,
      });
      closeModal();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleClear = () => {
    openModal(
      <ConfirmModal
        actionText="Clear sensor settings"
        description="Are you sure you want to discard the sensor settings?"
        onConfirm={async () => {
          setValues((prevState) => ({
            ...prevState,
            irSensorThreshold: NaN,
            irSensorBoardId: NaN,
          }));
        }}
      />,
    );
  };

  const handleSampleSensor = () => {
    openModal(
      <ScanDrawerSensorModal
        storageId={drawer.storageId}
        drawerName={drawer.name || `Drawer ${drawer.value}`}
        onSuccess={async (settings) => {
          setValues((prevState) => ({
            ...prevState,
            irSensorBoardId: settings.irSensorBoardId,
            irSensorThreshold: settings.irSensorThreshold,
          }));
        }}
      />,
    );
  };

  const handleShapeClick = (shape: SVGShape) => {
    setDrawerShape(shape);
  };

  const hasValues = typeof values.irSensorBoardId === "number" && typeof values.irSensorThreshold === "number";
  const canSampleThreshold = storage?.online;
  return (
    <StyledModal>
      <ModalHeader title="Drawer configuration">
        <TextBody textColor="text-gray-500">Configure the toolbox drawer and sensor settings.</TextBody>
      </ModalHeader>
      <Content>
        <HSpace className="flex-1">
          <SVGContainer>
            {shapes.length ? (
              <DrawerSVG
                viewBox={viewbox}
                shapes={shapes}
                value={[drawerShape?.id]}
                groupShape={groupShape}
                onClick={handleShapeClick}
              />
            ) : (
              <Uploader
                input={{
                  name: "file",
                  placeholder: "Upload a SVG file or drag and drop",
                }}
                value={files}
                onChange={handleUpload}
              />
            )}
          </SVGContainer>
        </HSpace>
        <VSpace>
          <SensorSettingsForm>
            <HSpace className="justify-between align-center">
              <Heading level="h3">Sensor settings</Heading>
              {hasValues && <TrashButton onClick={handleClear}>Clear config</TrashButton>}
            </HSpace>
            <VSpace>
              <SecondaryButton
                leadingIcon={RiSignalTowerFill}
                disabled={!canSampleThreshold}
                onClick={handleSampleSensor}
              >
                Scan automatically
              </SecondaryButton>
            </VSpace>
            <FieldGroup label="Sensor board">
              <NumberInput
                input={{
                  name: "irSensorBoardId",
                  step: "1",
                  min: 0,
                  placeholder: "Enter sensor board",
                }}
                value={values.irSensorBoardId}
                onChange={handleNumberChange}
              />
            </FieldGroup>
            <FieldGroup label="Sensor threshold">
              <NumberInput
                input={{
                  name: "irSensorThreshold",
                  step: "1",
                  min: 0,
                  placeholder: "Enter sensor threshold",
                }}
                value={values.irSensorThreshold}
                onChange={handleNumberChange}
              />
            </FieldGroup>
          </SensorSettingsForm>
          <HSpace>
            <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
            <ModalPrimaryActionButton type="submit" onClick={handleSubmit}>
              Save changes
            </ModalPrimaryActionButton>
          </HSpace>
        </VSpace>
      </Content>
    </StyledModal>
  );
};
