import { getMe } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";
import { FixLater } from "types";
import { checkRoleHasCapability } from "components/Restricted/Restricted";

type RestrictHasAllCapabilityProps = FixLater;

export const RestrictHasAllCapability = ({
  capabilities,
  alternate = null,
  children,
}: RestrictHasAllCapabilityProps): JSX.Element | null => {
  const me = useSelector(getMe);
  if (!me) {
    return null;
  }
  for (const capabilityID of capabilities) {
    if (!checkRoleHasCapability(me, capabilityID)) {
      return alternate;
    }
  }
  return children;
};
