import { FixLater, TProximitySensorReport, TProximitySensorReportValue, TStorageAsset, TStorageDrawer } from "types";

export type ProcessedProximitySensorValue = {
  boardId: number;
  sensorNumber: number;
  value: number;
};

export type ProcessedDrawerProximitySensorValue = {
  boardId: number;
  drawerValue: number;
};

export type UsedSensor = {
  irSensorBoardId: number;
  irSensorNumber: number;
};

export const processProximityDrawerSensorReports = (
  response: TProximitySensorReport[],
): ProcessedDrawerProximitySensorValue[] => {
  // Split the response into individual boards
  const drawerValuesByBoardMap = response.reduce((values: any, report: any) => {
    return {
      ...values,
      [report.boardId]: [...(values[report.boardId] || []), report.drawerValue],
    };
  }, {});

  return Object.keys(drawerValuesByBoardMap).map((boardIdKey: string) => {
    const boardId = parseInt(boardIdKey);
    const sensorValues = drawerValuesByBoardMap[boardIdKey];
    const average = sensorValues.reduce((a: number, b: number) => a + b, 0) / sensorValues.length;
    return {
      boardId,
      drawerValue: average,
    };
  });
};

export const processProximitySensorReports = (response: TProximitySensorReport[]): ProcessedProximitySensorValue[] => {
  // Split the response into individual boards
  const boards = response.reduce((values: any, report: any) => {
    return {
      ...values,
      [report.boardId]: [...(values[report.boardId] || []), ...report.values],
    };
  }, {});

  const output = Object.keys(boards)
    .map((boardIdKey: string) => {
      const boardId = parseInt(boardIdKey);
      const sensorValues = boards[boardIdKey];
      const reduced: Record<string, number[]> = sensorValues.reduce(
        (values: FixLater, sensorValue: TProximitySensorReportValue) => {
          return {
            ...values,
            [sensorValue.position]: [...(values[sensorValue.position] || []), sensorValue.value],
          };
        },
        {},
      );

      return Object.keys(reduced).map((key: string): ProcessedProximitySensorValue => {
        const sensorNumber = parseInt(key);
        const values = reduced[key];
        const average = values.reduce((a: number, b: number) => a + b, 0) / values.length;
        return {
          boardId,
          sensorNumber,
          value: average,
        };
      });
    })
    .reduce((final: ProcessedProximitySensorValue[], byBoardArray: ProcessedProximitySensorValue[]) => {
      return [...final, ...byBoardArray];
    }, []);

  return output;
};

export const findAssetTakenFromProximitySensorReportValues = (
  clean: ProcessedProximitySensorValue[],
  dirty: ProcessedProximitySensorValue[],
): {
  clean?: ProcessedProximitySensorValue;
  dirty?: ProcessedProximitySensorValue;
} => {
  let largestDifference: number = 0;
  let currentCleanNode: ProcessedProximitySensorValue | null = null;
  let currentDirtyNode: ProcessedProximitySensorValue | null = null;

  for (const cleanNode of clean) {
    const dirtyNode = dirty.find(
      ({ boardId, sensorNumber }) => cleanNode.boardId === boardId && cleanNode.sensorNumber === sensorNumber,
    );
    if (!dirtyNode) {
      continue;
    }
    const difference = cleanNode.value - dirtyNode.value;
    if (difference > largestDifference) {
      largestDifference = difference;
      currentCleanNode = cleanNode;
      currentDirtyNode = dirtyNode;
    }
  }

  return {
    clean: currentCleanNode || undefined,
    dirty: currentDirtyNode || undefined,
  };
};

export const findDrawerOpenedFromProximitySensorReportValues = (
  clean: ProcessedDrawerProximitySensorValue[],
  dirty: ProcessedDrawerProximitySensorValue[],
): {
  clean?: ProcessedDrawerProximitySensorValue;
  dirty?: ProcessedDrawerProximitySensorValue;
} => {
  let largestDifference: number = 0;
  let currentClean: ProcessedDrawerProximitySensorValue | null = null;
  let currentDirty: ProcessedDrawerProximitySensorValue | null = null;

  for (const cleanNode of clean) {
    const dirtyNode = dirty.find(({ boardId }) => cleanNode.boardId === boardId);
    if (!dirtyNode) {
      continue;
    }
    const difference = dirtyNode.drawerValue - cleanNode.drawerValue;
    if (difference > largestDifference) {
      largestDifference = difference;
      currentClean = cleanNode;
      currentDirty = dirtyNode;
    }
  }

  return {
    clean: currentClean || undefined,
    dirty: currentDirty || undefined,
  };
};

export type StorageAssetSensorSettings = Pick<
  TStorageAsset,
  "irSensorBoardId" | "irSensorNumber" | "irSensorThreshold"
>;

export type StorageDrawerSensorSettings = Pick<TStorageDrawer, "irSensorBoardId" | "irSensorThreshold">;

export const calculateProximitySettings = (
  clean: ProcessedProximitySensorValue,
  dirty: ProcessedProximitySensorValue,
): StorageAssetSensorSettings => {
  return {
    irSensorNumber: dirty.sensorNumber,
    irSensorBoardId: dirty.boardId,
    irSensorThreshold: Math.floor(clean.value - (clean.value - dirty.value) * 0.6),
  };
};

export const calculateDrawerProximitySettings = (
  clean: ProcessedDrawerProximitySensorValue,
): Partial<TStorageDrawer> => {
  return {
    irSensorBoardId: clean.boardId,
    irSensorThreshold: Math.floor(clean.drawerValue + 50), // Constant offset in mm of 50mm.
  };
};
