import { useParams } from "react-router-dom";
import { validate } from "uuid";
import { UUID } from "types";

export const useAssetIdFromParams = (): number | UUID => {
  const { assetID = "", section } = useParams();
  const isUUID = validate(assetID);
  if (isUUID) return assetID;
  return parseInt(assetID);
};
