import { getCustomer } from "lib/reducers/lookupData.selectors";
import { customerHasApp } from "lib/utility/customerHasApp.util";
import { useSelector } from "store";
import { FixLater } from "types";

type RestrictByAppProps = FixLater;

export const RestrictByApp = ({ app, alternate = null, children }: RestrictByAppProps) => {
  const customer = useSelector(getCustomer);
  if (!customer) {
    return null;
  }
  return customerHasApp(customer.apps, app) ? children : alternate;
};
