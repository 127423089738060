import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RealtimeProvider } from "contexts/RealtimeProvider/RealtimeProvider";
import { AppRouter } from "routes";
import store from "store";
import { ModalProvider } from "components/modals";
import { SlideOverProvider } from "components/shared";

console.log(`version ${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_GIT_SHA})`);

function App() {
  return (
    <ReduxProvider store={store}>
      <RealtimeProvider>
        <BrowserRouter>
          <ModalProvider>
            <SlideOverProvider>
              <AppRouter />
            </SlideOverProvider>
          </ModalProvider>
        </BrowserRouter>
      </RealtimeProvider>
    </ReduxProvider>
  );
}

export default App;
