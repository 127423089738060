import {
  HiOutlineCash,
  HiOutlineClipboardCheck,
  HiOutlineQrcode,
  HiOutlineDatabase,
  HiOutlineCog,
} from "react-icons/hi";
import { useAssetIdFromParams } from "effects/useAssetIdFromParams";
import useSetSidebar from "effects/useSidebar";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectAssetByID } from "store/reducers";
import { Restrict } from "components/Restricted";
import { Header } from "components/Typography";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import style from "components/sidebars/sidebar.module.scss";
import Sidebar, { MenuSeparator } from "./Sidebar";

export const AssetSidebar = () => {
  useSetSidebar(true);
  const assetId = useAssetIdFromParams();
  const asset = useSelector(selectAssetByID(assetId));

  return (
    <Sidebar>
      {asset?.catalogProduct && (
        <div className={style.sidebarImageHeader}>
          <img src={asset.catalogProduct.imageUrl} className={style.assetImage} alt="" />
          <Header>{asset.catalogProduct.name}</Header>
        </div>
      )}
      <MenuSeparator />
      <MenuItem to={`/apps/tracking/assets/${assetId}/summary`} icon={<HiOutlineDatabase />}>
        Information
      </MenuItem>
      <Restrict capability={Capabilities.ASSET_UPDATE}>
        <MenuItem to={`/apps/tracking/assets/${assetId}/tracking`} icon={<HiOutlineQrcode />}>
          Tracking
        </MenuItem>
      </Restrict>
      <MenuItem to={`/apps/tracking/assets/${assetId}/calibration`} icon={<HiOutlineClipboardCheck />}>
        Calibration
      </MenuItem>
      <MenuItem to={`/apps/tracking/assets/${assetId}/history`} icon="/images/icons/update_2.svg">
        Activity history
      </MenuItem>
      <MenuItem to={`/apps/tracking/assets/${assetId}/financials`} icon={<HiOutlineCash />}>
        Financials
      </MenuItem>
      <Restrict capability={Capabilities.ASSET_UPDATE}>
        <MenuSeparator />
        <MenuItem to={`/apps/tracking/assets/${assetId}/settings`} icon={<HiOutlineCog />}>
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};
